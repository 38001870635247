









































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '../misc/ErrorMessageHandler.mixin';
import { mixins } from 'vue-class-component';
import { required, email, minLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import Company from '@/models/Company';
import { namespace } from 'vuex-class';
import { companyStoreActions } from '@/store/company.store';

const CompanyStore = namespace('company');

@Component({
    mixins: [validationMixin],
    validations: {
        companyCopy: {
            name: {
                required,
                minLength: minLength(3)
            },
            street: {
                required,
                minLength: minLength(3)
            },
            postBox: {
                required,
                minLength: minLength(1)
            },
            city: {
                required,
                minLength: minLength(3)
            },
            phone: {
                required,
                minLength: minLength(5)
            },
            email: {
                required,
                email
            }
        }
    }
})
export default class EditCompanyComponent extends mixins(ErrorMessageHandlerMixin) {

    @Prop({ default: () => new Company() })
    public company!: Company;

    @CompanyStore.Action(companyStoreActions.CREATE)
    public createCompanyAction!: (payload: { company: Company }) => Promise<Company>;

    @CompanyStore.Action(companyStoreActions.UPDATE)
    public updateCompanyAction!: (payload: { company: Company }) => Promise<Company>;

    public companyCopy: Company = new Company();
    public validData = false;
    public isLoading: boolean = false;
    private countryCode: string = '+264';

    @Watch('company', { immediate: true })
    public companyChanged() {
        if (this.company) {
            this.companyCopy = this.company.copy() as Company;
            this.validData = !this.$v!.$invalid;
        }
    }

    private onSelect(payload: {dialCode: string, iso2: string, name: string}): void {
        this.countryCode = `+${payload.dialCode}`;
    }

    /**
     * User status to select from
     */
    private selectStatus: { text: string, value: boolean } [] = [
        { text: this.$t('COMPANY.DIALOG.STATUS_ACTIVE').toString(), value: true },
        { text: this.$t('COMPANY.DIALOG.STATUS_INACTIVE').toString(), value: false }
    ];

    public async saveData() {
        const companyToUse = Company.parseFromObject({
            ...this.companyCopy,
            phone: `${this.countryCode}${this.companyCopy.phone}`
        });

        if (this.validData) {
            try {
                this.isLoading = true;
                if (!this.companyCopy.id) {
                    await this.createCompanyAction({ company: companyToUse });
                    this.$notifySuccessSimplified('COMPANY.CREATED_SUCCESS');
                } else {
                    await this.updateCompanyAction({ company: companyToUse });
                    this.$notifySuccessSimplified('COMPANY.EDITED_SUCCESS');
                }
                this.dismiss(true);
            } catch (err) {
                if (err.status === 409) { // Duplicate
                    Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.DUPLICATE_COMPANY_ERROR');
                } else {
                    Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.SERVER_ERROR');
                }
            } finally {
                this.isLoading = false;
            }
        }
    }

    public dismiss(reloadData: boolean = false) {
        if (reloadData) {
            this.companyCopy = new Company();
        }

        this.$v.$reset();
        this.$emit('closeDialog', reloadData);
    }

    public checkForm(type: string) {
        this.validData = !this.$v!.$invalid;
        this.triggerValidation(type);
    }
}

